import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const HeroStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroPresentation = styled.section`
  text-align: center;
  width: 567px;
  margin-bottom: 1.5em;
  h1 {
    margin: 0;
    margin-bottom: 0.5em;
  }
  p {
    margin: 0;
    margin-bottom: 1em;
  }
`;

const HeroList = styled.section`
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-top: 0;
  }
  .hl-container {
    display: flex;
    justify-content: space-around;
    width: 1100px;
    .hl-item {
      display: flex;
      flex-direction: column;
      flex: 1 0 30%;
      margin-right: 1em;
      h1 {
        font-size: 1.4em;
        margin-bottom: 1em;
      }
      p {
        margin-top: 0;
      }
    }
  }
`;
function Hero() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <HeroStyled>
      <HeroPresentation>
        <h1>{t("use_product_placement.title")}</h1>
        <p>{t("use_product_placement.desc_1")}</p>
        <p>{t("use_product_placement.desc_2")}</p>
      </HeroPresentation>
      <HeroList>
        <h1>{t("use_product_placement.list_title")}</h1>
        <div className="hl-container">
          <div className="hl-item">
            <div>
              <img src="" alt="" />
            </div>
            <h1>{t("use_product_placement.list_item_1_title")}</h1>
            <p>{t("use_product_placement.list_item_1_desc")}</p>
          </div>
          <div className="hl-item">
            <div>
              <img src="" alt="" />
            </div>
            <h1>{t("use_product_placement.list_item_2_title")}</h1>
            <p>{t("use_product_placement.list_item_2_desc")}</p>
          </div>
          <div className="hl-item">
            <div>
              <img src="" alt="" />
            </div>
            <h1>{t("use_product_placement.list_item_3_title")}</h1>
            <p>{t("use_product_placement.list_item_3_desc")}</p>
          </div>
        </div>
      </HeroList>
    </HeroStyled>
  );
}

export default Hero;
