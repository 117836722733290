import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const NeermeAllowsStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    flex: 0 0 40%;
    margin: 1.4em 1em;
    font-size: 1.1em;
  }
  .nas-list {
    border-radius: 24px;
    background-color: #fdf8f9;
    padding: 2em 3em;
    width: 1100px;
  }
  p {
    width: 40%;
    text-align: center;
    margin: 3em 0;
  }
  hr {
    border-color: #7070700f;
    width: 65%;
  }
`;

function NeermeAllows() {
  const { t } = useTranslation(["why_neerme"]);

  return (
    <NeermeAllowsStyled>
      <div className="nas-list">
        <h1>{t("allow_title")}</h1>
        <ul>
          <li>{t("allow_item_1")}</li>
          <li>{t("allow_item_2")}</li>
          <li>{t("allow_item_3")}</li>
          <li>{t("allow_item_4")}</li>
          <li>{t("allow_item_5")}</li>
          <li>{t("allow_item_6")}</li>
        </ul>
      </div>
      <p>{t("experience_p")}</p>
      <hr />
    </NeermeAllowsStyled>
  );
}

export default NeermeAllows;
