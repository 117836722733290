import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import check_green from "../../../../assets/check_green.svg"

const FeaturesIncludedStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3em;
`;

const FeaturesGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  justify-content: center;
  .fgc-card {
    margin: 1em;
    .fgc-card_header {
      background-color: #59385c;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      color: white;
      padding: 1em 0;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    ul {
      margin: 0;
      padding: 2em;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      border: solid 1px #e6e6e6;
      border-bottom-left-radius: 22px;
      border-bottom-right-radius: 22px;
      height: 450px;
      width: 260px;
      text-align: initial;
      list-style: none;
      li {
        margin-bottom: 1.8em;
        font-size: .95em;
        &::before {
          display: inline-block;
          vertical-align: middle;
          margin-right: .7em;
          content: " ";
          width: 15px;
          height: 15px;
          background: url(${check_green}) no-repeat center center;
          background-size: contain;
        }
      }
    }
    h2 {
      margin: 0;
      font-size: 1.2em;
      font-weight: bold;
    }
    h3 {
      margin: 0%;
      font-size: 0.8em;
    }
  }
`;

function FeaturesIncluded() {
  const { t } = useTranslation(["pricing"]);

  return (
    <FeaturesIncludedStyled>
      <div>
        <h1>{t("whats_included_title")}</h1>
      </div>
      <FeaturesGridContainer>
        <div className="fgc-card">
          <div className="fgc-card_header">
            <h2>{t("included_1_title")}</h2>
            <h3>(via boletia.com)</h3>
          </div>
          <ul>
            <li>{t("included_1_item_1")}</li>
            <li>{t("included_1_item_2")}</li>
            <li>{t("included_1_item_3")}</li>
            <li>{t("included_1_item_4")}</li>
            <li>{t("included_1_item_5")}</li>
            <li>{t("included_1_item_6")}</li>
            <li>{t("included_1_item_7")}</li>
            <li>{t("included_1_item_8")}</li>
          </ul>
        </div>
        <div className="fgc-card">
          <div className="fgc-card_header">
            <h2>{t("included_2_title")}</h2>
          </div>
          <ul>
            <li>{t("included_2_item_1")}</li>
            <li>{t("included_2_item_2")}</li>
            <li>{t("included_2_item_3")}</li>
            <li>{t("included_2_item_4")}</li>
          </ul>
        </div>
        <div className="fgc-card">
          <div className="fgc-card_header">
            <h2>{t("included_3_title")}</h2>
          </div>
          <ul>
            <li>{t("included_3_item_1")}</li>
            <li>{t("included_3_item_2")}</li>
            <li>{t("included_3_item_3")}</li>
            <li>{t("included_3_item_4")}</li>
          </ul>
        </div>
        <div className="fgc-card">
          <div className="fgc-card_header">
            <h2>{t("included_4_title")}</h2>
          </div>
          <ul>
            <li>{t("included_4_item_1")}</li>
            <li>{t("included_4_item_2")}</li>
            <li>{t("included_4_item_3")}</li>
            <li>{t("included_4_item_4")}</li>
          </ul>
        </div>
        <div className="fgc-card">
          <div className="fgc-card_header">
            <h2>{t("included_5_title")}</h2>
          </div>
          <ul>
            <li>{t("included_5_item_1")}</li>
            <li>{t("included_5_item_2")}</li>
            <li>{t("included_5_item_3")}</li>
            <li>{t("included_5_item_4")}</li>
          </ul>
        </div>
      </FeaturesGridContainer>
    </FeaturesIncludedStyled>
  );
}

export default FeaturesIncluded;
