import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Accordion from "@components/shared/Accordion";

const QuestionsStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4em 0;
  .qs-container {
    width: 900px;
    p {
      margin: 0;
      color: #848484;
    }
  }
`;

function Questions() {

  const { t } = useTranslation(["pricing"]);

  return (
    <QuestionsStyled>
      <div className="qs-container">
        <h1>{t("faq_title")}</h1>
        <Accordion
          title={t("faq_1_title")}
        >
          <p>
          {t("faq_1_desc")}
          </p>
        </Accordion>
        <Accordion
          title={t("faq_2_title")}
        >
          <p>
          {t("faq_2_desc")}
          </p>
        </Accordion>
        <Accordion
          title={t("faq_3_title")}
        >
          <p>
          {t("faq_3_desc")}
          </p>
        </Accordion>
      </div>
    </QuestionsStyled>
  );
}

export default Questions;
