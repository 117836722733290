import * as React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import NeermeLogo from "./NeermeLogo";
import Menu from "./Menu";
import TranslateSwitch from "./TranslateSwitch";
import LinkButton from "@components/shared/LinkButton";

const StyledHeader = styled.header`
  display: flex;
  padding: 1.5em 0;
  justify-content: space-around;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 2em;
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

function Header() {
  const { t } = useTranslation(["home"]);

  return (
    <StyledHeader>
      <ItemHeader style={{ width: "264px", justifyContent: "start" }}>
        <Link to="/">
          <NeermeLogo width={130} />
        </Link>
      </ItemHeader>

      <ItemHeader>
        <Menu />
      </ItemHeader>

      <ItemHeader>
        <LinkButton router rounded type="simple" href="/request-demo">
          {t("request_demo")}
        </LinkButton>
        <div style={{ marginLeft: "1.5em" }}></div>
        <TranslateSwitch />
      </ItemHeader>
    </StyledHeader>
  );
}

export default Header;
