import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import symb1 from "../../../../../assets/symb1.svg";

const TestimonialsStyled = styled.section`
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  .ts-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1100px;
    display: flex;
  }
  .ts-item.testimonial-list {
    width: 100%;
    border-radius: 20px;
    border: solid 1px #d8d8d8;
    padding: 3em 0;
    margin: 4em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TestimonialContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1em;
  width: 900px;
`;

const Testimonial = styled.div`
  flex: 1 0 50%;
  margin-bottom: 2.5em;
  width: 400px;
  .t-comment {
    display: flex;
    align-items: flex-start;
    p {
      margin: 0;
      margin-left: 1em;
      font-size: 0.9em;
      line-height: 1.75;
      color: #7a7a7a;
    }
  }
  .t-profile {
    display: flex;
    margin-top: 1.5em;
    img {
      border-radius: 50%;
      width: 50px;
    }
    .t-profile_names {
      margin-left: 2em;
      h3 {
        margin: 0;
        font-size: 1.3em;
        font-weight: bold;
      }
      p {
        margin: 0;
      }
    }
  }
`;
function Testimonials() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <TestimonialsStyled>
      <div className="ts-container">
        <div className="ts-item">
          <p>{t("use_concert.testimonial_desc_1")}</p>
          <p>{t("use_concert.testimonial_desc_2")}</p>
        </div>
        <div className="ts-item testimonial-list">
          <TestimonialContainer>
            <Testimonial>
              <div className="t-comment">
                <img src={symb1} alt="trail" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </p>
              </div>
              <div className="t-profile">
                <div>
                  <img src="https://picsum.photos/300" alt="Testimonial" />
                </div>
                <div className="t-profile_names">
                  <h3>John Doe</h3>
                  <p>Demo inc mexico</p>
                </div>
              </div>
            </Testimonial>
            <Testimonial>
              <div className="t-comment">
                <img src={symb1} alt="trail" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </p>
              </div>
              <div className="t-profile">
                <div>
                  <img src="https://picsum.photos/300" alt="Testimonial" />
                </div>
                <div className="t-profile_names">
                  <h3>John Doe</h3>
                  <p>Demo inc mexico</p>
                </div>
              </div>
            </Testimonial>
            <Testimonial>
              <div className="t-comment">
                <img src={symb1} alt="trail" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </p>
              </div>
              <div className="t-profile">
                <div>
                  <img src="https://picsum.photos/300" alt="Testimonial" />
                </div>
                <div className="t-profile_names">
                  <h3>John Doe</h3>
                  <p>Demo inc mexico</p>
                </div>
              </div>
            </Testimonial>
            <Testimonial>
              <div className="t-comment">
                <img src={symb1} alt="trail" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </p>
              </div>
              <div className="t-profile">
                <div>
                  <img src="https://picsum.photos/300" alt="Testimonial" />
                </div>
                <div className="t-profile_names">
                  <h3>John Doe</h3>
                  <p>Demo inc mexico</p>
                </div>
              </div>
            </Testimonial>
          </TestimonialContainer>
        </div>
      </div>
    </TestimonialsStyled>
  );
}

export default Testimonials;
