import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import LinkButton from "@components/shared/LinkButton";
import ScrollTop from "@components/shared/ScrollTop";

import uc_concert from "../../../../../assets/pictures/use_cases/uc_concert.webp";

const ContentStyled = styled.section`
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  .cs-container {
    justify-content: center;
    align-items: center;
    width: 1100px;
    display: flex;
  }
  .cs-item {
    flex: 1 0 50%;
    &.text {
      padding: 1em;
    }
  }
`;

function Content() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <ContentStyled>
      <ScrollTop />
      <div className="cs-container">
        <div className="cs-item">
          <img src={uc_concert} width="456" alt="" />
        </div>
        <div className="cs-item text">
          <h1>{t("use_concert.content_title")}</h1>
          <p>{t("use_concert.content_p_1")}</p>
          <p>{t("use_concert.content_p_2")}</p>
          <LinkButton type="simple" rounded href="">
            {t("request_demo")}
          </LinkButton>
        </div>
      </div>
    </ContentStyled>
  );
}

export default Content;
