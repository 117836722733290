import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import LinkButton from "@components/shared/LinkButton";

import dollar from "../../../../assets/dollar.svg";
import outline from "../../../../assets/outline.svg";
import touch from "../../../../assets/touch.svg";
import tools from "../../../../assets/tools.svg";
import nums_img from "../../../../assets/nums_img.webp";

const FeaturesBigStyled = styled.section`
  height: calc(100vh - 101px);
  display: flex;
  justify-content: center;
  align-items: center;
  .hf-container {
    background: #2d3142;
    color: white;
    height: 100%;
    flex: 1 0 60%;
    display: flex;
    padding: 0 3em;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 2.5em;
      margin-bottom: 1.5em;
      margin-left: 1.3em;
    }
  }
  .hf-feature_dual {
    display: flex;
    justify-content: space-between;
    .hf-feature_item {
      flex: 0 0 40%;
      display: flex;
      .hf-feature_image {
        margin-right: 1em;
        min-width: 39px;
        img {
          margin-top: 0.5em;
        }
      }
      h3 {
        font-size: 1.3em;
        margin: 0;
        p {
          font-size: 1.1em;
          margin-top: 0.3em;
        }
      }
    }
  }
  .fbs-image {
    flex: 1 0 30%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${nums_img});
  }
  .hf-button {
    margin-top: 2em;
    margin-left: 3.3em;
  }
`;

function FeaturesBig() {
  const { t } = useTranslation(["home"]);

  return (
    <FeaturesBigStyled>
      <div className="hf-container">
        <h1>{t("features_title")}</h1>
        <div className="hf-feature_list">
          <div className="hf-feature_dual">
            <div className="hf-feature_item">
              <div className="hf-feature_image">
                <img src={tools} alt="" />
              </div>
              <div className="text-contet">
                <h3>{t("features_one_title")}</h3>
                <p>{t("features_one_desc")}</p>
              </div>
            </div>
            <div className="hf-feature_item">
              <div className="hf-feature_image">
                <img src={outline} alt="" />
              </div>

              <div className="text-contet">
                <h3>{t("features_two_title")}</h3>
                <p>{t("features_two_desc")}</p>
              </div>
            </div>
          </div>
          <div className="hf-feature_dual">
            <div className="hf-feature_item">
              <div className="hf-feature_image">
                <img src={dollar} alt="" />
              </div>

              <div className="text-contet">
                <h3>{t("features_three_title")}</h3>
                <p>{t("features_three_desc")}</p>
              </div>
            </div>
            <div className="hf-feature_item">
              <div className="hf-feature_image">
                <img src={touch} alt="" />
              </div>

              <div className="text-contet">
                <h3>{t("features_four_title")}</h3>
                <p>{t("features_four_desc")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="hf-button">
          <LinkButton
            router
            inverted
            type="simple"
            href="/features/registration"
          >
            {t("know_tools")}
          </LinkButton>
        </div>
      </div>
      <div className="fbs-image"></div>
    </FeaturesBigStyled>
  );
}

export default FeaturesBig;
