import * as React from "react";

function ScrollToTopOnMount() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return null;
}

export default ScrollToTopOnMount;
