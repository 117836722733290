import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import why_1 from "../../../../assets/pictures/why_neerme/why_1.webp";

const HeroStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroPresentation = styled.section`
  text-align: center;
  margin-bottom: 1.5em;
  h1 {
    margin: 0;
  }
  p {
    margin-bottom: 2em;
  }
`;

const WhatsNeerme = styled.section`
  display: flex;
  margin-bottom: 5em;
  width: 1100px;
  justify-content: center;
  align-items: center;
  .wn-item {
    .wn-image-desc {
      font-weight: bold;
    }
    h1 {
      margin: 0;
      font-size: 1.7em;
    }
    &.text {
      margin: 0 2.4em;
    }
  }
`;

function Hero() {
  const { t } = useTranslation(["why_neerme"]);

  return (
    <HeroStyled>
      <HeroPresentation>
        <h1>{t("title_1")}</h1>
        <h1>{t("title_2")}</h1>
        <p>{t("desc")}</p>
      </HeroPresentation>
      <WhatsNeerme>
        <div className="wn-item text">
          <h1>{t("whats_neerme_title")}</h1>
          <p>{t("whats_neerme_p_1")}</p>

          <p>{t("whats_neerme_p_2")}</p>

          <p>{t("whats_neerme_p_3")}</p>
        </div>
        <div className="wn-item">
          <img src={why_1} alt="" width="480" />
          <p className="wn-image-desc">{t("image_des")}</p>
        </div>
      </WhatsNeerme>
    </HeroStyled>
  );
}

export default Hero;
