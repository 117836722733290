import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import exp_1 from "../../../../assets/pictures/home/exp_1.png";
import exp_2 from "../../../../assets/pictures/home/exp_2.png";
import exp_3 from "../../../../assets/pictures/home/exp_3.png";

const HeroExperienciesStyled = styled.section`
  height: calc(100vh - 133px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  .he-text_list_holder {
    flex: 0 1 33%;
    h1 {
      font-size: 2.3em;
      font-weight: 800;
      line-height: 0.9;
      margin: 0;
    }
    h2 {
      font-size: 14px;
  font-weight: bold;
    }
    p {
      font-size: 1.1em;
      color: #919191;
      margin: 0.9em 0em;
    }
    ul {
      color: #919191;
      font-size: 1.1em;
      li {
        margin-bottom: 0.2em;
      }
    }
  }
  .he-image_holder {
    margin-right: 5em;
    display:flex;
  }
  .he-img-left{
    margin-right: 1em;
  }
`;

function HeroExperiencies() {
  const { t } = useTranslation(["home"]);

  return (
    <HeroExperienciesStyled>
      <div className="he-image_holder">
        <div className="he-img-left">
          <div style={{marginBottom: "1em"}}><img src={exp_1} alt="Crowd 1" width="176" /></div>
          <div><img src={exp_2} alt="Crowd 1" width="176" /></div>
        </div>
        <div className="he-img-rigth">
        <img src={exp_3} alt="Crowd 1" width="295" />
        </div>
        
      </div>
      <div className="he-text_list_holder">
        <h1>{t("experience_title")}</h1>
        <p>{t("experience_desc_1")}</p>
        <h2>{t("experience_list_title")}</h2>

        <ul>
          <li>{t("experience_list_item_1")}</li>
          <li>{t("experience_list_item_2")}</li>
          <li>{t("experience_list_item_3")}</li>
          <li>{t("experience_list_item_4")}</li>
        </ul>
      </div>
    </HeroExperienciesStyled>
  );
}

export default HeroExperiencies;
