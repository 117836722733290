import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import LinkButton from "@components/shared/LinkButton";

import eng_1 from "../../../../assets/pictures/features/eng_1.webp";
import eng_2 from "../../../../assets/pictures/features/eng_2.webp";
import eng_3 from "../../../../assets/pictures/features/eng_3.webp";
import eng_4 from "../../../../assets/pictures/features/eng_4.webp";

import head from "../../../../assets/inter-head.png";

const HeroPresentation = styled.section`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 133px);
  .hero-image {
    flex: 1 0 50%;
  }
  .texts {
    h1 {
      margin: 0;
    }
    p {
      margin-bottom: 2em;
    }
  }
`;

const InteractionStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .is-content {
    display: flex;
    margin-bottom: 5em;
    width: 1100px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 133px);
    .is-item {
      h1 {
        margin: 0;
        font-size: 1.7em;
      }
      &.text {
        width: 480px;
        margin: 0 2em;
      }
    }
  }
`;

interface Props {
  menuRef: React.RefObject<HTMLDivElement> | null;
}

function Interaction(props: Props) {
  const { menuRef } = props;

  const { t } = useTranslation(["features"]);

  React.useEffect(() => {
    if (menuRef) {
      menuRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InteractionStyled>
      <HeroPresentation>
        <div className="hero-image">
          <img src={head} width="570" alt="" />
        </div>
        <div className="texts">
          <h1>{t("interaction.title_1")}</h1>
          <p>{t("interaction.desc")}</p>
        </div>
      </HeroPresentation>

      <div className="is-content">
        <div className="is-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("interaction.block_1_title_1")}</h1>
          <p>{t("interaction.block_1_desc_1")}</p>
          <p>{t("interaction.block_1_desc_2")}</p>
        </div>
        <div className="is-item">
          <img src={eng_1} alt="" width="360" />
        </div>
      </div>

      <div className="is-content">
        <div className="is-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("interaction.block_2_title_1")}</h1>
          <h1>{t("interaction.block_2_title_2")}</h1>
          <p>{t("interaction.block_2_desc_1")}</p>
          <p>{t("interaction.block_2_desc_2")}</p>
        </div>
        <div className="is-item">
          <img src={eng_2} alt="" width="300" />
        </div>
      </div>

      <div className="is-content">
        <div className="is-item" style={{ marginRight: "3.5rem" }}>
          <img src={eng_3} alt="" width="410" />
        </div>
        <div className="is-item text">
          <h1>{t("interaction.block_3_title_1")}</h1>
          <h1>{t("interaction.block_3_title_2")}</h1>
          <p>{t("interaction.block_3_desc_1")}</p>
        </div>
      </div>

      <div className="is-content">
        <div className="is-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("interaction.block_4_title_1")}</h1>
          <h1>{t("interaction.block_4_title_2")}</h1>
          <p>{t("interaction.block_4_desc_1")}</p>
          <LinkButton router rounded type="simple" href="/request-demo">
            {t("request_demo")}
          </LinkButton>
        </div>
        <div className="is-item">
          <img src={eng_4} alt="" width="320" />
        </div>
      </div>
    </InteractionStyled>
  );
}

export default Interaction;
