import * as React from "react";

import BaseLayout from "@components/layout/BaseLayout";

import Hero from "./Hero";
import Testimonials from "./Testimonials";
import Content from "./Content";

function UseCaseCongress() {
  return (
    <BaseLayout>
      <Hero />
      <Content />
      <Testimonials />
    </BaseLayout>
  );
}

export default UseCaseCongress;
