import * as React from "react";

import BaseLayout from "@components/layout/BaseLayout";
import ScrollTop from "@components/shared/ScrollTop";

import Hero from "./Hero";
import FeaturesIncluded from "./FeaturesIncluded";
import Questions from "./Questions";

function Pricing() {
  return (
    <BaseLayout>
      <ScrollTop />
      <Hero />
      <FeaturesIncluded />
      <Questions />
    </BaseLayout>
  );
}

export default Pricing;
