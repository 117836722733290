import * as React from "react";

import { useTranslation } from "react-i18next";

import styled from "styled-components";

interface SwitchLinkProps {
  active?: boolean;
}

const SwitchLink = styled.a<SwitchLinkProps>`
  margin: 0 0.3rem;
  font-size: 1.1em;
  opacity: ${(props) => (props.active ? 1 : 0.48)};
  ${(props) => props.active && "font-weight: bold;"};
`;

const Divider = styled.div`
  width: 1px;
  height: 1.7em;
  border-left: 1px solid black;
  margin: 0 0.2em;
`;

function TranslateSwitch() {
  const { i18n } = useTranslation();

  function onSwitchLang(lng: string) {
    i18n.changeLanguage(lng);
  }

  return (
    <>
      <SwitchLink
        active={i18n.language === "en"}
        href="#en"
        onClick={() => onSwitchLang("en")}
      >
        EN
      </SwitchLink>
      <Divider />
      <SwitchLink
        active={i18n.language === "es"}
        href="#es"
        onClick={() => onSwitchLang("es")}
      >
        ES
      </SwitchLink>
    </>
  );
}

export default TranslateSwitch;
