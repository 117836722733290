import * as React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "@components/pages/Home";
import Features from "@components/pages/Features";
import Pricing from "@components/pages/Pricing";
import WhyNeerme from "@components/pages/WhyNeerme";
import RequestDemo from "@components/pages/RequestDemo";

import UseCaseDigitalTheater from "@components/pages/UseCases/UseCaseDigitalTheater";
import UseCaseProductPlacement from "@components/pages/UseCases/UseCaseProductPlacement";
import UseCaseContentCreator from "@components/pages/UseCases/UseCaseContentCreator";
import UseCaseEmployee from "@components/pages/UseCases/UseCaseEmployee";
import UseCaseCongress from "@components/pages/UseCases/UseCaseCongress";
import UseCaseConference from "@components/pages/UseCases/UseCaseConference";
import UseCaseConcert from "@components/pages/UseCases/UseCaseConcert";
import ByTeamEventPromoters from "@components/pages/UseCases/ByTeamEventPromoters";
import ByTeamMeetingsPlanners from "@components/pages/UseCases/ByTeamMeetingsPlanners";
import ByTeamBrand from "@components/pages/UseCases/ByTeamBrand";
import ByTeamContentCreator from "@components/pages/UseCases/ByTeamContentCreator";
import ByTeamMarketing from "@components/pages/UseCases/ByTeamMarketing";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/features">
          <Features />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/why-neerme">
          <WhyNeerme />
        </Route>
        <Route path="/request-demo">
          <RequestDemo />
        </Route>
        <Route path="/use-cases/theater">
          <UseCaseDigitalTheater />
        </Route>
        <Route path="/use-cases/product-placement">
          <UseCaseProductPlacement />
        </Route>
        <Route path="/use-cases/content-creator">
          <UseCaseContentCreator />
        </Route>
        <Route path="/use-cases/employee">
          <UseCaseEmployee />
        </Route>
        <Route path="/use-cases/congress">
          <UseCaseCongress />
        </Route>
        <Route path="/use-cases/conference">
          <UseCaseConference />
        </Route>
        <Route path="/use-cases/concert">
          <UseCaseConcert />
        </Route>
        <Route path="/by-team/promoters">
          <ByTeamEventPromoters />
        </Route>
        <Route path="/by-team/meeting-planners">
          <ByTeamMeetingsPlanners />
        </Route>
        <Route path="/by-team/team-brand">
          <ByTeamBrand />
        </Route>
        <Route path="/by-team/content-creator">
          <ByTeamContentCreator />
        </Route>
        <Route path="/by-team/marketing">
          <ByTeamMarketing />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
