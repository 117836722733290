import * as React from "react";

import Slider from "react-slick";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import use_congress from "../../../../assets/pictures/home/use_congress.png";
import use_corp from "../../../../assets/pictures/home/use_corp.png";
import use_online from "../../../../assets/pictures/home/use_online.png";
import pinkdots from "../../../../assets/pinkdots.png";

const UseCasesSlidersStyled = styled.section`
  .BrainhubCarousel__dot::before {
    width: 10px !important;
    height: 10px !important;
    background: #e14f6f !important;
  }
  .BrainhubCarouselItem {
    justify-content: normal !important;
  }
  .slick-dots li button::before {
    color: #e14f6f;
    font-size: 1em;
  }
  .slick-dots li button {
    font-size: 1em;
  }
  .slick-dots {
    position: relative !important;
  }
  height: calc(100vh - 133px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  flex-direction: column;
  position: relative;
  .pink-dots {
    position: absolute;
    width: 150px;
    height: 102px;
    background-size: cover;
    right: 13%;
    bottom: 30%;
    z-index: -1;
    background-image: url(${pinkdots});
  }
  .btn-purple {
    border-radius: 9px;
    background-color: #e14f6f;
    color: white;
    border: 0;
    padding: 1em 2em;
    font-weight: bold;
  }
  .ucs-item {
    display: flex;
    align-items: center;
  }
  .he-text_list_holder {
    flex: 0 0 50%;
    h1 {
      font-size: 2.6em;
      font-weight: 700;
      line-height: 0.9;
      margin: 0;
    }
    h2 {
      font-size: 1.9em;
    }
    h3 {
      font-size: 1.2em;
      margin-top: 0;
    }
    p {
      font-size: 1.1em;
      color: #919191;
      margin: 0.9em 0em;
    }
    ul {
      color: #919191;
      font-size: 1.1em;
      li {
        margin-bottom: 0.2em;
      }
    }
  }
  .he-image_holder {
    margin-right: 5em;
    margin-left: -10px;
    flex: 0 0 45%;
    img {
      width: 100%;
    }
  }
`;

function UseCasesSliders() {
  const { t } = useTranslation(["home"]);

  const settings = {
    autoplay: true,
    arrows: false,
    infinite: true,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    autoplaySpeed: 2000
  };
 
  return (
    <UseCasesSlidersStyled>
      <div className="pink-dots"></div>
      <div style={{ marginBottom: "5em", width: "100%" }}>
        <Slider {...settings}>
          <div>
            <div className="ucs-item">
              <div className="he-image_holder">
                <img src={use_corp} alt="Crowd 1" width="350" />
              </div>
              <div className="he-text_list_holder">
                <h3>{t("corporate_events.desc_1")}</h3>
                <h2>{t("corporate_events.desc_2")}</h2>
                <p>{t("corporate_events.know_more")}</p>
                <button className="btn-purple">{t("know_how")}</button>
              </div>
            </div>
          </div>
          <div>
            <div className="ucs-item">
              <div className="he-image_holder">
                <img src={use_online} alt="Crowd 1" width="350" />
              </div>
              <div className="he-text_list_holder">
                <h3>{t("online_shows.desc_1")}</h3>
                <h2>{t("online_shows.desc_2")}</h2>
                <p>
                {t("online_shows.know_more")}
                </p>
                <button className="btn-purple">{t("know_how")}</button>
              </div>
            </div>
          </div>
          <div>
            <div className="ucs-item">
              <div className="he-image_holder">
                <img src={use_congress} alt="Crowd 1" width="350" />
              </div>
              <div className="he-text_list_holder">
              <h3>{t("congresses_and_conferences.desc_1")}</h3>
                <h2>{t("congresses_and_conferences.desc_2")}</h2>
                <p>
                {t("congresses_and_conferences.know_more")}
                </p>
                <button className="btn-purple">{t("know_how")}</button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </UseCasesSlidersStyled>
  );
}

export default UseCasesSliders;
