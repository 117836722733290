import * as React from "react";

import styled from "styled-components";

interface Props {
  title: string;
  children: React.ReactNode;
}

const AccordionStyled = styled.div`
  margin: 1.4em 0;
  & + * {
    margin-top: 0.5em;
  }

  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    margin-top: 0.7em;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-title {
    font-weight: bold;
    cursor: pointer;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid currentColor;
    }

    &:hover,
    &.open {
      color: black;
    }

    &.open {
      &::after {
        content: "";
        border-top: 0;
        border-bottom: 8px solid;
      }
    }
  }
`;

function Accordion({ title, children }: Props) {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  return (
    <AccordionStyled>
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </AccordionStyled>
  );
}

export default Accordion;
