import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import chart from "../../../../../assets/pictures/use_cases/online_shows_4.png";
import tagline from "../../../../../assets/pictures/use_cases/online_shows_5.png";
import arrow_top from "../../../../../assets/arrow_top.png";

const TestimonialsStyled = styled.section`
  height: calc(100vh - 233px);
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-purple {
    border-radius: 9px;
    background-color: #e14f6f;
    color: white;
    border: 0;
    padding: 1em 2em;
    font-weight: bold;
  }
  .ts-container {
    justify-content: center;
    align-items: center;
    width: 1100px;
    display: flex;
  }
  .ts-item {
    flex: 1 0 50%;
    &.text {
      padding: 1em;
      h1 {
        font-size: 1.7em;
      }
      p {
        font-size: 1em;
        color: #919191;
      }
      .combined-text {
        margin-top: 2em;
        display: flex;
        align-items: center;
        .number {
          font-weight: bold;
          color: #1bd84d;
          font-size: 1.7em;
        }
        span {
          margin-right: 0.5em;
        }
      }
    }
  }
`;

function Testimonials() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>
              ¿Invertiste en contenido? Ahora entiende a tu audiencia. Con
              Neerme tendrás datos clave para tomar decisiones estratégicas y de
              impacto.
            </p>
            <div className="combined-text">
              <div style={{ marginRight: ".5em" }}>
                <img src={arrow_top} width="25" alt="" />
              </div>
              <span className="number">30%</span>
              <span>mas vs el ultimo trimestre</span>
            </div>
          </div>
          <div className="ts-item">
            <img src={chart} width="456" alt="" />
          </div>
        </div>
      </TestimonialsStyled>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item">
            <img src={tagline} width="456" alt="" />
          </div>
          <div className="ts-item text">
            <h1>Case study</h1>

            <p>
              Una compañía quiso agradecerles a sus colaboradores el esfuerzo
              con una puesta de teatro exclusiva, ¿la solución?
            </p>
            <p>
              En Neerme les dimos una forma fácil para que más de mil empleados
              de diversas edades pudieran entrar con solo un click a un venue
              personalizable con una calidad audiovisual sobresaliente, se
              adecuó el website a su cultura empresarial, tuvieron herramientas
              de interacción únicas para este momento lúdico entre
              colaboradores. Todo esto con un presupuesto menor que si lo
              hubieran hecho de forma física.
            </p>
            <button className="btn-purple">{t("request_demo")}</button>
          </div>
        </div>
      </TestimonialsStyled>
      {/* <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>{t("use_congress.content_p_1")}</p>
            <LinkButton type="simple" rounded href="">
              {t("request_demo")}
            </LinkButton>
          </div>
          <div className="ts-item">
            <img
              src="https://i.picsum.photos/id/982/500/300.jpg?hmac=OBf4C-TflS5w6oQmTwfXyKBY_JGWx66Gm0Epqev_o90"
              width="456"
              alt=""
            />
          </div>
        </div>
      </TestimonialsStyled>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item">
            <img
              src="https://i.picsum.photos/id/982/500/300.jpg?hmac=OBf4C-TflS5w6oQmTwfXyKBY_JGWx66Gm0Epqev_o90"
              width="456"
              alt=""
            />
          </div>
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>{t("use_congress.content_p_1")}</p>
            <LinkButton type="simple" rounded href="">
              {t("request_demo")}
            </LinkButton>
          </div>
        </div>
      </TestimonialsStyled> */}
    </>
  );
}

export default Testimonials;
