import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TextTransition, { presets } from "react-text-transition";

import useOnScreen from "@hooks/useOnScreen";

import dots_arrow_left_ligth from "../../../../assets/dots_arrow_left_ligth.svg";
import dots_1 from "../../../../assets/dots_1.svg";
import stats from "../../../../assets/stats.webp";
import pinkdots from "../../../../assets/pinkdots.png";

import "./style.css";

const NumbersStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ns-big-h {
    height: calc(100vh - 133px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    .pink-dots {
      position: absolute;
      width: 150px;
      height: 102px;
      background-size: cover;
      right: 13%;
      bottom: 15%;
      z-index: -1;
      background-image: url(${pinkdots});
    }
  }
`;
const NumbersContainer = styled.section`
  display: flex;
  width: 100%;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.09);
  background-color: #fcfcfc;
  justify-content: center;
  padding: 6em 0;
  position: relative;

  .nc-dots {
    display: block;
    position: absolute;
    background: url(${dots_1}) no-repeat center center;
    background-size: auto;
    background-size: contain;
    height: 102px;
    right: 0;
    width: 150px;
    top: -14%;
  }
  .nc-arrow {
    display: block;
    position: absolute;
    background: url(${dots_arrow_left_ligth}) no-repeat center center;
    background-size: auto;
    background-size: contain;
    transform: rotate(180deg);
    height: 150px;
    left: 6%;
    width: 76px;
    bottom: 15%;
  }
  .nc-item {
    text-align: left;
    h1 {
      font-size: 3em;
      margin: 0;
    }
    p {
      width: 75%;
    }
  }
  .nc-item_numbers {
    display: flex;
    flex-wrap: wrap;
    margin-left: 6em;
    align-items: center;
    width: 515px;
  }
  .nc-item_numbers_number {
    width: 250px;
    flex: 0 1 46%;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    justify-content: start;
    h2 {
      margin: 0;
      font-size: 3.5em;
      font-weight: 800;
      span.sign {
        color: #e14f6f;
      }
    }
    p {
      margin: 0;
      font-size: 1em;
      width: 85%;
    }
  }
`;

const NeermeTypes = styled.div`
  width: 100%;
  margin: 6em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .nt-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nt-tags_container.negative {
    margin-left: -5em;
  }
  .nt-tags_container.negative2 {
    margin-left: -3em;
  }
  .nt-tags_container {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    flex: 1 0 30%;
  }
  .nt-tags_item {
    padding: 0.7em 1.7em;
    margin-left: 1.5em;
    border-radius: 24px;
    background-color: #2d3142;
    width: max-content;
    color: white;
    font-weight: 500;
  }
  .nt-tags_item.pink {
    background-color: #e14f6f;
  }
`;

const NeermeCreative = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4em;
  flex: 0 0 40%;
  h1 {
    margin: 0;
    font-size: 2.3em;
    margin-bottom: 0.2em;
  }
  p {
    margin-bottom: 1em;
    color: #919191;
    font-size: 1.4em;
  }
`;

const TEXTS_1 = [
  "Sesiones de Q&A",
  "Eventos Corporativos",
  "Lanzamientos de Marca",
];
const TEXTS_2 = ["Livestreams", "Hibridas", "Premiaciones digitales"];
const TEXTS_3 = ["Podcast Live", "Master Class", "Teatro digital"];
const TEXTS_4 = ["Shows Interactivos", "Expos en linea", "Meet & Greets"];
const TEXTS_5 = [
  "Conciertos interactivos",
  "Shows a la medida",
  "Talleres y cursos",
];
const TEXTS_6 = ["Festivales", "Giras Digitales", "Workshops"];
const TEXTS_7 = ["Foros Digitales", "Eventos para empleados", "Cuanta cuentos"];
const TEXTS_8 = ["Meet & Greets", "Podcast Live", "Hibridas"];
const TEXTS_9 = ["Livestreams", "Workshops", "Master Class"];

function Numbers() {
  const { t } = useTranslation(["home"]);
  const counters = React.useRef<Array<HTMLElement | null>>([]);
  const counterContRef = React.useRef(null);
  const [hasAnimated, setHasAnimated] = React.useState<boolean>(false);

  const [tags1, setTags1] = React.useState<number>(0);
  const [tags2, setTags2] = React.useState<number>(0);
  const [tags3, setTags3] = React.useState<number>(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setTags1((index) => index + 1),
      5500 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setTags2((index) => index + 1),
      7300 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setTags3((index) => index + 1),
      8700 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const numbersVisible = useOnScreen(counterContRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(animateNumbers, [numbersVisible]);

  function animateNumbers() {
    if (numbersVisible && !hasAnimated) {
      setHasAnimated(true);
      // How long you want the animation to take, in ms
      const animationDuration = 1500;
      // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
      const frameDuration = 1000 / 60;
      // Use that to calculate how many frames we need to complete the animation
      const totalFrames = Math.round(animationDuration / frameDuration);
      // An ease-out function that slows the count as it progresses
      const easeOutQuad = (t: number) => t * (2 - t);

      // The animation function, which takes an Element
      const animateCountUp = (el: HTMLElement) => {
        let frame = 0;
        const countTo = parseInt(el.getAttribute("data-number") || "0", 10);
        // Start the animation running 60 times per second
        const counter = setInterval(() => {
          frame++;
          // Calculate our progress as a value between 0 and 1
          // Pass that value to our easing function to get our
          // progress on a curve
          const progress = easeOutQuad(frame / totalFrames);
          // Use the progress value to calculate the current count
          const currentCount = Math.round(countTo * progress);

          // If the current count has changed, update the element
          if (parseInt(el.innerHTML, 10) !== currentCount) {
            el.innerHTML = currentCount.toString();
          }

          // If we’ve reached our last frame, stop the animation
          if (frame === totalFrames) {
            clearInterval(counter);
          }
        }, frameDuration);
      };

      counters.current.forEach((counter) => {
        if (counter) {
          animateCountUp(counter);
        }
      });
    }
  }

  return (
    <NumbersStyled>
      <div className="ns-big-h">
        <NumbersContainer>
          {/* <div className="nc-dots"></div>
          <div className="nc-arrow"></div> */}
          <div className="nc-item">
            <h1>{t("our_numbers_title")}</h1>
            <p style={{ marginBottom: "3em" }}>{t("our_numbers_desc")}</p>
            <img src={stats} alt="" width="170" />
          </div>
          <div className="nc-item nc-item_numbers">
            <div className="nc-item_numbers_number">
              <h2>
                <span
                  className="clients-count"
                  ref={(ref) => counters.current.push(ref)}
                  data-number="200"
                >
                  200
                </span>
                <span className="sign">+</span>
              </h2>
              <p>{t("our_numbers_one_title")}</p>
            </div>
            <div className="nc-item_numbers_number">
              <h2>
                <span
                  className="event-count"
                  ref={(ref) => counters.current.push(ref)}
                  data-number="500"
                >
                  500
                </span>
                <span className="sign">+</span>
              </h2>
              <p>{t("our_numbers_two_title")}</p>
            </div>
            <div className="nc-item_numbers_number">
              <h2>
                <span
                  className="viewers-count"
                  ref={(ref) => counters.current.push(ref)}
                  data-number="750"
                >
                  750
                </span>
                <span className="sign">k</span>
              </h2>
              <p>{t("our_numbers_three_title")}</p>
            </div>
            <div className="nc-item_numbers_number">
              <h2>
                <span
                  className="countries-count"
                  ref={(ref) => counters.current.push(ref)}
                  data-number="150"
                >
                  150
                </span>
                <span className="sign">+</span>
              </h2>
              <p>{t("our_numbers_four_title")}</p>
            </div>
          </div>
          <div ref={counterContRef}></div>
        </NumbersContainer>
      </div>
      <div className="ns-big-h">
        <div className="pink-dots"></div>
        <NeermeTypes>
          <div className="nt-container">
            <div style={{ width: "670px" }}>
              <div className="nt-tags_container negative">
                <div className="nt-tags_item pink">
                  <TextTransition
                    text={TEXTS_1[tags1 % TEXTS_1.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_2[tags2 % TEXTS_2.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_3[tags3 % TEXTS_3.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
              </div>
              <div className="nt-tags_container">
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_4[tags3 % TEXTS_4.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_5[tags2 % TEXTS_5.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item pink">
                  <TextTransition
                    text={TEXTS_6[tags1 % TEXTS_6.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
              </div>
              <div className="nt-tags_container negative2">
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_7[tags2 % TEXTS_7.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item pink">
                  <TextTransition
                    text={TEXTS_8[tags1 % TEXTS_8.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
                <div className="nt-tags_item">
                  <TextTransition
                    text={TEXTS_9[tags3 % TEXTS_9.length]}
                    springConfig={presets.wobbly}
                    noOverflow
                  />
                </div>
              </div>
            </div>
            <NeermeCreative>
              <h1>{t("creativity_title_1")}</h1>
              <p>{t("creativity_desc")}</p>
            </NeermeCreative>
          </div>
        </NeermeTypes>
      </div>
    </NumbersStyled>
  );
}

export default Numbers;
