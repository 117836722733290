import * as React from "react";

import styled from "styled-components";

import whitelogo from "../../../assets/whitelogo.png";

const StyledFooter = styled.footer`
  background-color: #272727;
  color: white;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  .fl-sub_container {
    display: flex;
  }
  ul {
    color: white;
    padding: 0;
    margin-right: 10rem;
    list-style: none;
  }
  li {
    margin-bottom: 1.5rem;
  }
  li:first-child {
    opacity: 0.27;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;
`;

function Footer() {
  return (
    <StyledFooter>
      <FooterContent>
        <FooterLinks>
          <LogoContainer><img src={whitelogo} alt="" width="123" /></LogoContainer>
          <div className="fl-sub_container">
            <ul>
              <li>Politicas</li>
              <li>Términos y condiciones</li>
              <li>Aviso de privacidad</li>
              <li>Blog</li>
            </ul>
            <ul>
              <li>Redes</li>
              <li>Instagram</li>
              <li>Facebook</li>
              <li>Twitter</li>
            </ul>
            <ul>
              <li>Soporte</li>
              <li>Habla con soporte</li>
              <li>Preguntas frecuentes</li>
              <li>Contacto</li>
            </ul>
          </div>
        </FooterLinks>
      </FooterContent>
    </StyledFooter>
  );
}

export default Footer;
