import * as React from "react";

import Header from "@components/layout/Header";
import Footer from "@components/layout/Footer";

interface Props {
  children: React.ReactNode;
}

function BaseLayout(props: Props) {
  const { children } = props;

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default BaseLayout;
