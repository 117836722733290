import * as React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";

type ButtonTypes = "primary" | "simple" | "dark";

interface Props extends StyledLinkButtonProps {
  href: string;
  alt?: string;
  target?: string;
  children: React.ReactNode;
  router?: boolean;
}

interface StyledLinkButtonProps {
  type?: ButtonTypes;
  rounded?: boolean;
  inverted?: boolean;
}

const buttonColors: { [key in ButtonTypes]: string } = {
  primary: "#e14f6f",
  simple: "transparent",
  dark: "#272727",
};

const StyledLinkButton = styled.a<StyledLinkButtonProps>`
  display: inline-block;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 16px 36px;
  width: max-content;
  transition: all 0.2s linear;
  background-color: ${(props) =>
    props.type ? buttonColors[props.type] : buttonColors.primary};
  color: ${(props) =>
    props.type === "simple" ? (props.inverted ? "white" : "black") : "white"};
  ${(props) => props.rounded && "border-radius: 10px;"}
  ${(props) =>
    props.type === "simple" &&
    `border: solid 1px ${props.inverted ? "white" : "black"};`}
    ${(props) =>
    props.type === "simple" &&
    `&:hover{
        background-color: ${buttonColors["dark"]};
        color: white;
      }`}
`;

function LinkButton(props: Props) {
  const { children, router, ...rest } = props;
  if (router) {
    return (
      <Link to={rest.href}>
        <StyledLinkButton {...rest}>{children}</StyledLinkButton>
      </Link>
    );
  } else {
    return <StyledLinkButton {...rest}>{children}</StyledLinkButton>;
  }
}

export default LinkButton;
