import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ScrollTop from "@components/shared/ScrollTop";

import uc_congress from "../../../../../assets/pictures/use_cases/congr_1.png";

const ContentStyled = styled.section`
  .btn-purple {
    border-radius: 9px;
    background-color: #e14f6f;
    color: white;
    border: 0;
    padding: 1em 2em;
    font-weight: bold;
  }
  height: calc(100vh - 233px);
  display: flex;
  align-items: center;
  justify-content: center;
  .cs-container {
    justify-content: center;
    align-items: center;
    width: 1100px;
    display: flex;
  }
  .cs-item {
    flex: 1 0 50%;
    &.text {
      padding: 1em;
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 1em;
        color: #919191;
      }
    }
  }
`;

function Content() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <ContentStyled>
      <ScrollTop />
      <div className="cs-container">
        <div className="cs-item">
          <img src={uc_congress} width="456" alt="" />
        </div>
        <div className="cs-item text">
          <h1>
            Rompe la barrera física y maximiza tu audiencia en un streaming.
          </h1>
          <p>
            ¿Invertiste en contenido? Ahora entiende a tu audiencia. Con Neerme
            tendrás datos clave para tomar decisiones estratégicas y de impacto.
          </p>
          <button className="btn-purple">{t("request_demo")}</button>
        </div>
      </div>
    </ContentStyled>
    // <ContentStyled>
    //   <ScrollTop />
    //   <div className="cs-container">
    //     <div className="cs-item">
    //       <img src={uc_congress} width="456" alt="" />
    //     </div>
    //     <div className="cs-item text">
    //       <h1>{t("use_congress.content_title")}</h1>
    //       <p>{t("use_congress.content_p_1")}</p>
    //       <LinkButton type="simple" rounded href="">
    //         {t("request_demo")}
    //       </LinkButton>
    //     </div>
    //   </div>
    // </ContentStyled>
  );
}

export default Content;
