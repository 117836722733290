import * as React from "react";

import BaseLayout from "@components/layout/BaseLayout";
import ScrollTop from "@components/shared/ScrollTop";

import Hero from "./Hero";
import NeermeAllows from "./NeermeAllows";
import TheStory from "./TheStory";

function WhyNeerme() {
  return (
    <BaseLayout>
      <ScrollTop />
      <Hero />
      <NeermeAllows />
      <TheStory />
    </BaseLayout>
  );
}

export default WhyNeerme;
