import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import LinkButton from "@components/shared/LinkButton";

const HeroStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PresentationSection = styled.section`
  margin-bottom: 1.5em;
  text-align: center;
  h1 {
    margin: 0;
  }
  p {
    margin: 1em 0;
  }
  .ps-titles {
    margin-bottom: 3em;
  }
`;

const PricingSection = styled.section`
  display: flex;
  .ps-pricing_basic {
    display: flex;
    border: 0.5em solid #2d3142;
    border-radius: 1em;
    margin-right: 2em;
    background-color: #2d3142;
    .ps-pricing_basic_price {
      background-color: #2d3142;
      padding: 1em;
      color: white;
      display: flex;
      flex-direction: column;
      .ps-pricing_basic_price_detail {
        padding: 1em;
        background-color: #ffffff17;
        border-radius: 9px;
        height: 100%;
        width: 235px;
        h2 {
          margin: 0;
          font-weight: normal;
        }
        h3 {
          margin-top: 0.2em;
          margin-bottom: 0;
        }
        hr {
          border-color: white;
          margin: 1.5em 0;
        }
        small {
          color: #ffffff8a;
        }
      }
      h1 {
        font-size: 1.2em;
      }
    }
  }
  .ps-pricing_basic_addons {
    padding: 1em 3em;
    background-color: white;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    h2 {
      font-size: 1em;
    }
    ul {
      padding-left: 0em;
      list-style: none;
      li {
        margin-bottom: 1em;
      }
      h3 {
        margin: 0;
        font-weight: normal;
      }
      h4 {
        margin: 0;
        margin-top: 0.2em;
      }
      small {
        color: #17171770;
      }
    }
  }
  .ps-pricing_custom {
    border-radius: 21px;
    border: solid 1px #c9c9c9;
    background-color: #ffffff;
    padding: 2.5em 3em;
    display: flex;
    flex-direction: column;
    p {
      width: 355px;
    }
    h1 {
      font-size: 1.4em;
    }
    hr {
      width: 100%;
      border-color: #70707014;
    }
    .ps-pricing_custom_detail_container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .ps-pricing_custom_detail_list {
        p {
          margin: 0;
        }
        ul {
          padding-left: 1em;
          margin-top: 2em;
        }
        li {
          margin-bottom: 1em;
        }
      }
    }
  }
`;

function Hero() {
  const { t } = useTranslation(["pricing"]);

  return (
    <HeroStyled>
      <PresentationSection>
        <div className="ps-titles">
          <h1>{t("hero_title_1")}</h1>
          <h1>{t("hero_title_2")}</h1>
          <p>{t("hero_desc")}</p>
        </div>
      </PresentationSection>
      <PricingSection>
        <div className="ps-pricing_basic">
          <div className="ps-pricing_basic_price">
            <h1>{t("scheme_basic_title")}</h1>
            <div className="ps-pricing_basic_price_detail">
              <h2>{t("setup_fee")}</h2>
              <h3>{t("setup_fee_desc")}</h3>
              <hr />
              <h2>{t("event_access")}</h2>
              <h3>{t("event_access_desc")}</h3>
              <small>{t("event_access_info")}</small>
            </div>
          </div>
          <div className="ps-pricing_basic_addons">
            <h2>{t("optional_title")}</h2>
            <ul>
              <li>
                <h3>{t("extra_hour_title")}</h3>
                <h4>{t("extra_hour_desc")}</h4>
              </li>
              <li>
                <h3>{t("ticket_sale")}</h3>
                <h4>{t("ticket_sale_desc")}</h4>
                <small>{t("ticket_sale_info")}</small>
              </li>
              <li>
                <h3>{t("merch_store")}</h3>
                <h4>{t("merch_store_desc")}</h4>
                <small>{t("merch_store_info")}</small>
              </li>
              <li>
                <h3>{t("monetization")}</h3>
                <h4>{t("monetization_desc")}</h4>
                <small>{t("monetization_info")}</small>
              </li>
            </ul>
          </div>
        </div>
        <div className="ps-pricing_custom">
          <h1>{t("custom_pricing_title")}</h1>
          <hr />
          <div className="ps-pricing_custom_detail_container">
            <div className="ps-pricing_custom_detail_list">
              <p>{t("custom_pricing_desc")}</p>
              <ul>
                <li>{t("custom_pricing_item_1")}</li>
                <li>{t("custom_pricing_item_2")}</li>
                <li>{t("custom_pricing_item_3")}</li>
              </ul>
            </div>
            <div>
              <LinkButton router type="dark" href="/request-demo">
                {t("talk_us")}
              </LinkButton>
            </div>
          </div>
        </div>
      </PricingSection>
    </HeroStyled>
  );
}

export default Hero;
