import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

// import rob_1_1 from "../../../../assets/pictures/features/rob_1_1.webp";
import rob_1_2 from "../../../../assets/pictures/features/rob_1_2.webp";
import rob_2 from "../../../../assets/pictures/features/rob_2.webp";
import rob_3 from "../../../../assets/pictures/features/rob_3.webp";
import rob_4 from "../../../../assets/pictures/features/rob_4.webp";
import rob_5 from "../../../../assets/pictures/features/rob_5.webp";

import head from "../../../../assets/rob-head.png";

import LinkButton from "@components/shared/LinkButton";

const HeroPresentation = styled.section`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 133px);
  .hero-image {
    flex: 1 0 50%;
  }
  .texts {
    h1 {
      margin: 0;
    }
    p {
      margin-bottom: 2em;
    }
  }
`;

const SecurityStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ss-content {
    display: flex;
    margin-bottom: 5em;
    width: 1100px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 133px);
    .ss-item {
      h1 {
        margin: 0;
        font-size: 1.7em;
      }
      &.text {
        width: 480px;
        margin: 0 2em;
      }
    }
  }
`;

interface Props {
  menuRef: React.RefObject<HTMLDivElement> | null;
}

function Security(props: Props) {
  const { menuRef } = props;
  const { t } = useTranslation(["features"]);

  React.useEffect(() => {
    if (menuRef) {
      menuRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecurityStyled>
      <HeroPresentation>
        <div className="hero-image">
          <img src={head} width="570" alt="" />
        </div>
        <div className="texts">
          <h1>
            {t("security.title_1")} {t("security.title_2")}
          </h1>
          <p>{t("security.desc")}</p>
        </div>
      </HeroPresentation>
      <div className="ss-content">
        <div className="ss-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("security.block_1_title_1")}</h1>
          <h1>{t("security.block_1_title_2")}</h1>
          <p>{t("security.block_1_desc_1")}</p>
        </div>
        <div className="ss-item">
          <img src={rob_1_2} alt="" width="454" />
        </div>
      </div>

      <div className="ss-content">
        <div className="ss-item" style={{ marginRight: "3.5rem" }}>
          <img src={rob_2} alt="" width="192" />
        </div>
        <div className="ss-item text">
          <h1>{t("security.block_2_title_1")}</h1>
          <h1>{t("security.block_2_title_2")}</h1>
          <p>{t("security.block_2_desc_1")}</p>
          <p>{t("security.block_2_desc_2")}</p>
        </div>
      </div>
      <div className="ss-content">
        <div className="ss-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("security.block_3_title_1")}</h1>
          <h1>{t("security.block_3_title_2")}</h1>
          <p>{t("security.block_3_desc_1")}</p>
          <p>{t("security.block_3_desc_2")}</p>
        </div>
        <div className="ss-item">
          <img src={rob_3} alt="" width="190" />
        </div>
      </div>

      <div className="ss-content">
        <div className="ss-item" style={{ marginRight: "3.5rem" }}>
          <img src={rob_4} alt="" width="300" />
        </div>
        <div className="ss-item text">
          <h1>{t("security.block_4_title_1")}</h1>
          <p>{t("security.block_4_desc_1")}</p>
          <p>{t("security.block_4_desc_2")}</p>
        </div>
      </div>
      <div className="ss-content">
        <div className="ss-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("security.block_5_title_1")}</h1>
          <p>{t("security.block_5_desc_1")}</p>
          <p>{t("security.block_5_desc_2")}</p>
          <LinkButton router rounded type="simple" href="/request-demo">
            {t("request_demo")}
          </LinkButton>
        </div>
        <div className="ss-item">
          <img src={rob_5} alt="" width="190" />
        </div>
      </div>
    </SecurityStyled>
  );
}

export default Security;
