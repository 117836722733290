import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import chart from "../../../../../assets/pictures/use_cases/ee_3.png";
import tagline from "../../../../../assets/pictures/use_cases/ee_5.png";
import arrow_top from "../../../../../assets/arrow_top.png";

const TestimonialsStyled = styled.section`
  height: calc(100vh - 233px);
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-purple {
    border-radius: 9px;
    background-color: #e14f6f;
    color: white;
    border: 0;
    padding: 1em 2em;
    font-weight: bold;
  }
  .ts-container {
    justify-content: center;
    align-items: center;
    width: 1100px;
    display: flex;
  }
  .ts-item {
    flex: 1 0 50%;
    &.text {
      padding: 1em;
      h1 {
        font-size: 1.7em;
      }
      p {
        font-size: 1em;
        color: #919191;
      }
      .combined-text {
        margin-top: 2em;
        display: flex;
        align-items: center;
        .number {
          font-weight: bold;
          color: #1bd84d;
          font-size: 1.7em;
        }
        span {
          margin-right: 0.5em;
        }
      }
    }
  }
`;

function Testimonials() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>
              ¿Invertiste en contenido? Ahora entiende a tu audiencia. Con
              Neerme tendrás datos clave para tomar decisiones estratégicas y de
              impacto.
            </p>
          </div>
          <div className="ts-item">
            <img src={chart} width="456" alt="" />
          </div>
        </div>
      </TestimonialsStyled>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item">
            <img src={tagline} width="456" alt="" />
          </div>
          <div className="ts-item text">
            <h1>Case study</h1>
            <p>
              ¿Es posible abrir un canal de ingresos adicional a los existentes
              que me de cientos de miles de dólares mensualmente?
            </p>
            <p>
              Un artista con una base de fans en Youtube de 500 mil suscriptores
              lograron adicional a lo que generaban de monetización en su canal,
              hacer un show único que además de conectar con su audiencia de
              forma más personal monetizaba 4x más por un show de dos horas.
            </p>
            <button className="btn-purple">{t("request_demo")}</button>
          </div>
        </div>
      </TestimonialsStyled>
      {/* <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>{t("use_congress.content_p_1")}</p>
            <LinkButton type="simple" rounded href="">
              {t("request_demo")}
            </LinkButton>
          </div>
          <div className="ts-item">
            <img
              src="https://i.picsum.photos/id/982/500/300.jpg?hmac=OBf4C-TflS5w6oQmTwfXyKBY_JGWx66Gm0Epqev_o90"
              width="456"
              alt=""
            />
          </div>
        </div>
      </TestimonialsStyled>
      <TestimonialsStyled>
        <div className="ts-container">
          <div className="ts-item">
            <img
              src="https://i.picsum.photos/id/982/500/300.jpg?hmac=OBf4C-TflS5w6oQmTwfXyKBY_JGWx66Gm0Epqev_o90"
              width="456"
              alt=""
            />
          </div>
          <div className="ts-item text">
            <h1>Nuestros resultados</h1>
            <p>{t("use_congress.content_p_1")}</p>
            <LinkButton type="simple" rounded href="">
              {t("request_demo")}
            </LinkButton>
          </div>
        </div>
      </TestimonialsStyled> */}
    </>
  );
}

export default Testimonials;
