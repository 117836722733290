import * as React from "react";

import Slider from "react-slick";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import symb1 from "../../../../assets/symb1.svg";
import h_1 from "../../../../assets/testimonials/h_1.jpeg";
import h_2 from "../../../../assets/testimonials/h_2.png";

interface TestimonialProps {
  readonly active?: boolean;
}

const TestimonialsLessStyled = styled.section`
  display: flex;
  justify-content: center;
  height: calc(100vh - 101px);
  align-items: center;
  flex-direction: column;
  .slick-slide.slick-active.slick-center.slick-current {
    z-index: 9999;
    position: relative;
  }
`;

const TestimonialContainer = styled.section`
  margin: 1em;
  margin-top: 3em;
  width: 100%;
`;

const Testimonial = styled.div<TestimonialProps>`
  border-radius: 16px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  padding: 2em;
  transition: transform 300ms;
  width: 450px !important;
  height: 235px;
  ${(props) => {
    if (!props.active) {
      return `
      opacity: 0.67;
      transform: scale(0.7);
    `;
    }
  }}

  .t-comment {
    display: flex;
    align-items: flex-start;
    p {
      margin: 0;
      margin-left: 1em;
      font-size: 0.9em;
      line-height: 1.75;
      color: #7a7a7a;
    }
  }
  .t-profile {
    display: flex;
    margin-bottom: 1em;
    margin-left: 1em;
    img {
      border-radius: 50%;
      width: 50px;
    }
    .t-profile_names {
      margin-left: 1em;
      h3 {
        margin: 0;
        font-size: 1.3em;
        font-weight: bold;
      }
      p {
        margin: 0;
      }
    }
  }
`;

function TestimonialsLess() {
  const { t } = useTranslation(["home"]);

  const [slideIndex, setSlideIndex] = React.useState<number>(0);

  const settings = {
    className: "center",
    autoplay: true,
    arrows: false,
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow: 3,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    autoplaySpeed: 3000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Omar Carrasco",
      description: "Xperience Makers",
      testimonial: t("testimonial_1"),
      image: h_1,
    },
    {
      name: "Arturo Saldivar",
      description: "Xperience Makers",
      testimonial: t("testimonial_2"),
      image: h_2,
    },
    {
      name: "Omar Carrasco",
      description: "Xperience Makers",
      testimonial: t("testimonial_1"),
      image: h_1,
    },
    {
      name: "Arturo Saldivar",
      description: "Xperience Makers",
      testimonial: t("testimonial_2"),
      image: h_2,
    },
  ];

  return (
    <TestimonialsLessStyled>
      <TestimonialContainer>
        <Slider {...settings}>
          {testimonials.map((testimonial, idx) => {
            return (
              <Testimonial
                data-index={idx}
                active={idx === slideIndex}
                key={idx}
              >
                <div className="t-profile">
                  <div>
                    <img
                      src={testimonial.image}
                      width="300"
                      alt="Testimonial"
                    />
                  </div>
                  <div className="t-profile_names">
                    <h3>{testimonial.name}</h3>
                    <p>{testimonial.description}</p>
                  </div>
                </div>
                <div className="t-comment">
                  <img src={symb1} alt="trail" />
                  <p>{testimonial.testimonial}</p>
                </div>
              </Testimonial>
            );
          })}
        </Slider>
      </TestimonialContainer>
    </TestimonialsLessStyled>
  );
}

export default TestimonialsLess;
