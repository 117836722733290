import * as React from "react";

import Router from "./Router";
import Loading from "@components/layout/Loading";

import "./style.scss";

function App() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Router />
    </React.Suspense>
  );
}

export default App;
