import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import head_congress from "../../../../../assets/pictures/use_cases/online_shows_1.png";
import benefits from "../../../../../assets/pictures/use_cases/online_shows_2.png";

const HeroStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroPresentation = styled.section`
  width: 1110px;
  height: calc(100vh - 233px);
  display: flex;
  align-items: center;
  .info {
    flex: 0 0 45%;
    margin-left: 6em;
    h1 {
      margin: 0;
      font-size: 2.8em;
      margin-bottom: 0.5em;
    }
    p {
      margin: 0;
      margin-bottom: 1em;
    }
  }
`;

const HeroList = styled.section`
  width: 1110px;
  height: calc(100vh - 233px);
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 0;
  }
  .hl-container {
    display: flex;
    justify-content: space-around;
    width: 1100px;
    position: relative;
    .item-1 {
      bottom: 15%;
      text-align: right;
      left: -20%;
    }
    .item-2 {
      top: 0%;
      right: -20%;
    }
    .item-3 {
      bottom: 0%;
      right: -20%;
    }
    .hl-item {
      display: flex;
      flex-direction: column;
      flex: 1 0 30%;
      position: absolute;
      width: 535px;
      h1 {
        font-size: 1.3em;
        margin-bottom: 1em;
      }
      p {
        margin-top: 0;
        font-size: 1em;
      }
    }
  }
`;
function Hero() {
  const { t } = useTranslation(["use_cases"]);

  return (
    <HeroStyled>
      <HeroPresentation>
        <div>
          <img src={head_congress} width="550" alt="" />
        </div>
        <div className="info">
          <h1>Shows en linea</h1>
          <p>
            Magnifica tu audiencia y monetiza mejor que en plataformas
            convencionales. Un paidstreaming te ayuda a poner el foco en tu
            contenido y llegar a nuevas audiencias.
          </p>
        </div>
      </HeroPresentation>
      <HeroList>
        <div className="hl-container">
          <img src={benefits} alt="" width="450" />
          <div className="hl-item item-1">
            <div>
              <img src="" alt="" />
            </div>
            <h1>Robustez</h1>
            <p>
              Neerme está al nivel de las mejores plataformas de la industria
              con récords de cientos de miles de personas.
            </p>
          </div>
          <div className="hl-item item-2">
            <div>
              <img src="" alt="" />
            </div>
            <h1>Interacción</h1>
            <p>
              Los fans quieren interactuar con su artista, bríndales la
              oportunidad de hacerlo con herramientas de interacción
              especializadas y genera márgenes extra al creativo.
            </p>
          </div>
          <div className="hl-item item-3">
            <div>
              <img src="" alt="" />
            </div>
            <h1>Viralidad de tu contenido</h1>
            <p>
              Concentra a tus fans en un solo momento y haz viral tu contenido,
              todo con una plataforma especializada y que previene la
              retransmisión ilegal de tu contenido.
            </p>
          </div>
        </div>
      </HeroList>
    </HeroStyled>
    // <HeroStyled>
    //   <HeroPresentation>
    //     <div>
    //       <img src={head_congress} width="700" alt="" />
    //     </div>
    //     <div className="info">
    //       <h1>{t("use_congress.title")}</h1>
    //       <p>{t("use_congress.desc_2")}</p>
    //     </div>
    //   </HeroPresentation>
    //   <HeroList>
    //     <div className="hl-container">
    //       <img src={benefits} alt="" width="450" />
    //       <div className="hl-item item-1">
    //         <div>
    //           <img src="" alt="" />
    //         </div>
    //         <h1>{t("use_congress.list_item_1_title")}</h1>
    //         <p>{t("use_congress.list_item_1_desc")}</p>
    //       </div>
    //       <div className="hl-item item-2">
    //         <div>
    //           <img src="" alt="" />
    //         </div>
    //         <h1>{t("use_congress.list_item_2_title")}</h1>
    //         <p>{t("use_congress.list_item_2_desc")}</p>
    //       </div>
    //       <div className="hl-item item-3">
    //         <div>
    //           <img src="" alt="" />
    //         </div>
    //         <h1>{t("use_congress.list_item_3_title")}</h1>
    //         <p>{t("use_congress.list_item_3_desc")}</p>
    //       </div>
    //     </div>
    //   </HeroList>
    // </HeroStyled>
  );
}

export default Hero;
