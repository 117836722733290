import * as React from "react";

import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import down_arrow from "../../../../assets/down-arrow.svg";

const StyledMenu = styled.ul`
  padding: 0;
  li {
    list-style-type: none;
    margin-right: 30px;
    float: left;
    display: block;
    position: relative;
  }
  a {
    transition: all 0.2s ease-in;
    &.active {
      font-weight: bold;
    }
  }

  li.has-sub {
    margin-right: 44px;
    transition: all 0.2s ease-in;
    ul a {
      &.active {
        opacity: 0.38;
      }
    }
    a.sub:after {
      display: block;
      position: absolute;
      content: "";
      width: 12px;
      height: 100%;
      top: 0%;
      right: -21px;
      background: url(${down_arrow}) no-repeat center center;
      background-size: contain;
    }
  }

  li:focus-within a {
    outline: none;
  }

  li ul {
    visibility: hidden;
    opacity: 0;
    min-width: 7rem;
    position: absolute;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    margin-top: 0rem;
    left: 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 1em;
    padding-left: 1.5em;
    width: max-content;
    display: flex;
    padding-top: 2em;
    z-index: 10;
    div {
      margin-right: 3em;
    }
    li {
      transition: all 0.2s ease-in;
      &:hover:not(.no-animate) {
        padding-left: 0.5em;
      }
    }
  }

  li:hover > ul,
  li ul:hover {
    visibility: visible;
    opacity: 1;
  }

  li ul li {
    clear: both;
    width: 100%;
    margin-bottom: 1em;
  }
`;

function Menu() {
  const { t } = useTranslation(["features", "translation"]);

  return (
    <>
      <StyledMenu>
        <li>
          <NavLink exact className="sub" activeClassName="active" to="/">
            {t("translation:menu.home")}
          </NavLink>
        </li>
        <li className="has-sub">
          <NavLink
            className="sub"
            activeClassName="active"
            to="/features/registration"
          >
            {t("translation:menu.features")}
          </NavLink>
          <ul aria-label="submenu">
            <div>
              <li>
                <NavLink activeClassName="active" to="/features/registration">
                  {t("features:topic_1")}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/features/interaction">
                  {t("features:topic_2")}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/features/security">
                  {t("features:topic_3")}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/features/customization">
                  {t("features:topic_4")}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/features/monetization">
                  {t("features:topic_5")}
                </NavLink>
              </li>
            </div>
          </ul>
        </li>

        <li className="has-sub">
          <Link className="sub" to="#use-case">
            {t("translation:menu.use_cases")}
          </Link>
          <ul aria-label="submenu">
            <div>
              <li className="no-animate">
                <b>{t("translation:menu.pricing_items.use_case")}</b>
              </li>
              <li>
                <NavLink activeClassName="active" to="/use-cases/congress">
                  {t("translation:menu.pricing_items.item_1")}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/use-cases/employee">
                  {t("translation:menu.pricing_items.item_4")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/use-cases/content-creator"
                >
                  {t("translation:menu.pricing_items.item_5")}
                </NavLink>
              </li>
            </div>
          </ul>
        </li>
        <li>
          <NavLink activeClassName="active" to="/pricing">
            {t("translation:menu.pricing")}
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/why-neerme">
            {t("translation:menu.why_neerme")}
          </NavLink>
        </li>
      </StyledMenu>
    </>
  );
}

export default Menu;
