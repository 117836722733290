import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ScrollTop from "@components/shared/ScrollTop";

import LinkButton from "@components/shared/LinkButton";

import reg_1 from "../../../../assets/pictures/features/reg_1.webp";
import reg_2 from "../../../../assets/pictures/features/reg_2.webp";
import reg_3 from "../../../../assets/pictures/features/reg_3.webp";
import reg_4 from "../../../../assets/pictures/features/reg_4.webp";
import reg_5 from "../../../../assets/pictures/features/reg_5.webp";

import head from "../../../../assets/custom-head.png";

const HeroPresentation = styled.section`
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 133px);
  .hero-image {
    flex: 1 0 50%;
  }
  .texts {
    h1 {
      margin: 0;
    }
    p {
      margin-bottom: 2em;
    }
  }
`;

const RegistrationStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .rs-content {
    display: flex;
    margin-bottom: 5em;
    width: 1100px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 133px);

    .rs-item {
      h1 {
        margin: 0;
        font-size: 1.7em;
      }
      &.text {
        width: 480px;
        margin: 0 2em;
      }
    }
  }
`;

function Registration() {
  const { t } = useTranslation(["features"]);

  return (
    <RegistrationStyled>
      <ScrollTop />
      <HeroPresentation>
        <div className="hero-image">
          <img src={head} width="570" alt="" />
        </div>
        <div className="texts">
          <h1>
            {t("registration.title_1")} {t("registration.title_2")}
          </h1>

          <p>{t("registration.desc")}</p>
        </div>
      </HeroPresentation>

      <div className="rs-content">
        <div className="rs-item" style={{ marginRight: "3.5rem" }}>
          <img src={reg_1} alt="" height="300" />
        </div>
        <div className="rs-item text">
          <h1>{t("registration.block_1_title_1")}</h1>
          <h1>{t("registration.block_1_title_2")}</h1>
          <p>{t("registration.block_1_desc_1")}</p>
          <p>{t("registration.block_1_desc_2")}</p>
        </div>
      </div>

      <div className="rs-content">
        <div className="rs-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("registration.block_2_title_1")}</h1>
          <h1>{t("registration.block_2_title_2")}</h1>
          <p>{t("registration.block_2_desc_1")}</p>
          <p>{t("registration.block_2_desc_2")}</p>
        </div>
        <div className="rs-item">
          <img src={reg_2} alt="" width="450" />
        </div>
      </div>

      <div className="rs-content">
        <div className="rs-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("registration.block_3_title_1")}</h1>
          <h1>{t("registration.block_3_title_2")}</h1>
          <p>{t("registration.block_3_desc_1")}</p>
          <p>{t("registration.block_3_desc_2")}</p>
        </div>
        <div className="rs-item">
          <img src={reg_3} alt="" width="433" />
        </div>
      </div>

      <div className="rs-content">
        <div className="rs-item" style={{ marginRight: "3.5rem" }}>
          <img src={reg_4} alt="" width="377" />
        </div>
        <div className="rs-item text">
          <h1>{t("registration.block_4_title_1")}</h1>
          <h1>{t("registration.block_4_title_2")}</h1>
          <p>{t("registration.block_4_desc_1")}</p>
          <p>{t("registration.block_4_desc_2")}</p>
        </div>
      </div>

      <div className="rs-content">
        <div className="rs-item text" style={{ marginRight: "3.5rem" }}>
          <h1>{t("registration.block_5_title_1")}</h1>
          <h1>{t("registration.block_5_title_2")}</h1>
          <p>{t("registration.block_5_desc_1")}</p>
          <p>{t("registration.block_5_desc_2")}</p>
          <LinkButton router rounded type="simple" href="/request-demo">
            {t("request_demo")}
          </LinkButton>
        </div>
        <div className="rs-item">
          <img src={reg_5} alt="" width="454" />
        </div>
      </div>
    </RegistrationStyled>
  );
}

export default Registration;
