import * as React from "react";
import ITyped from "react-ityped";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import headpic from "../../../../assets/headpic.png";

const HeroBigStyled = styled.section`
  height: calc(100vh - 165px);
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2.5em;
  .hbs-banner {
    position: absolute;
    background-image: url(${headpic});
    height: 500px;
    width: 720px;
    right: -3%;
    background-size: cover;
    background-repeat: no-repeat;
}
  }
  h1 {
    font-size: 2.88em;
    margin: 0;
    font-size: 2.88em;
    font-weight: 800;
  }
  p {
    font-size: 1.2em;
    font-weight: normal;
    color: #919191;
  }
  .hbs-item {
    flex: 0 0 50%;
    margin-left: 5em;
}
  }
  .hbs-image {
    position: absolute;
    z-index: -1;
    right: -15%;
    img {
      height: 800px;
    }
  }
  .hbs-title-rotate {
    display: flex;
    align-items: center;
    h1 {
      font-size: 2.88em;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      margin-top: 0;
      max-height: max-content;
      display: flex;
      margin: 0;
      width: max-content;
    }
    .typed-cursor {
      background-color: #f2f2f2;
      width: max-content;
      height: 3.4em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1em;
      border-radius: 6px;
      padding: 0 1em;
      .typed-block {
        font-size: 2.88em;
        font-weight: 800;
        color: #343434;
      }
      .ityped-cursor {
        font-size: 2.88em;
      }
    }
  }
`;

function HeroBig() {
  const { t, i18n } = useTranslation(["home"]);

  const strings = React.useMemo(
    () => [
      t("made_for_1"),
      t("made_for_2"),
      t("made_for_3"),
      t("made_for_4_1"),
      t("made_for_4_2"),
      t("made_for_5"),
      t("made_for_6"),
    ],
    [i18n]
  );

  return (
    <HeroBigStyled>
      <div className="hbs-item">
        <h1>{t("hero_title")}</h1>
        <div className="hbs-title-rotate">
          <h1>{t("hero_desc_1")}</h1>
          <div className="typed-cursor">
            <ITyped
              className="typed-block"
              showCursor={true}
              strings={strings}
              typeSpeed={85}
              backSpeed={40}
              startDelay={400}
              backDelay={1200}
            />
          </div>
        </div>

        <p>{t("hero_desc_2")}</p>
      </div>
      <div className="hbs-banner"></div>
    </HeroBigStyled>
  );
}

export default HeroBig;
