import * as React from "react";

import { Fade } from "react-awesome-reveal";

import BaseLayout from "@components/layout/BaseLayout";
import ScrollTop from "@components/shared/ScrollTop";

import HeroBig from "./HeroBig";
import HeroExperiencies from "./HeroExperiencies";
import FeaturesBig from "./FeaturesBig";
import Numbers from "./Numbers";
import TestimonialsLess from "./TestimonialsLess";
import UseCasesSlider from "./UseCasesSlider";


function Home() {
  return (
    <BaseLayout>
      <ScrollTop />
      <Fade triggerOnce delay={600}>
        <HeroBig />
      </Fade>
      <UseCasesSlider />
      <Fade triggerOnce delay={600}>
        <FeaturesBig />
      </Fade>
      <TestimonialsLess />
      <Fade triggerOnce delay={600}>
        <HeroExperiencies />
      </Fade>
      <Fade triggerOnce delay={600}>
        <Numbers />
      </Fade>
    </BaseLayout>
  );
}

export default Home;
