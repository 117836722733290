import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import why_2 from "../../../../assets/pictures/why_neerme/why_2.webp";
import why_3 from "../../../../assets/pictures/why_neerme/why_3.webp";
import why_4 from "../../../../assets/pictures/why_neerme/why_4.webp";

const TheStoryStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
  .tss-container {
    width: 1100px;
    display: flex;
    flex-direction: column;

    .tss-content {
      display: flex;
      margin-bottom: 3em;
      .tss-item {
        flex: 1 0 50%;
        &.image {
          justify-content: center;
          align-items: center;
          display: flex;
        }
        p {
          width: 80%;
        }
      }
    }
  }
`;

function TheStory() {
  const { t } = useTranslation(["why_neerme"]);

  return (
    <TheStoryStyled>
      <div className="tss-container">
        <div>
          <h1>{t("story_title")}</h1>
          <p>{t("story_p_1")}</p>
          <p>{t("story_p_2")}</p>
          <div>
            <img src={why_2} alt="" width="100%" />
          </div>
        </div>
        <div className="tss-content" style={{ marginTop: "2em" }}>
          <div className="tss-item">
            <p>{t("story_p_3")}</p>
            <p>{t("story_p_4")}</p>
            <p>{t("story_p_5")}</p>
          </div>
          <div className="tss-item image">
            <img src={why_3} alt="" width="400" />
          </div>
        </div>
        <div className="tss-content">
          <div className="tss-item image">
            <img src={why_4} alt="" width="380" />
          </div>
          <div className="tss-item">
            <p>{t("story_p_6")}</p>
            <p>{t("story_p_7")}</p>
            <p>{t("story_p_8")}</p>
          </div>
        </div>
      </div>
    </TheStoryStyled>
  );
}

export default TheStory;
