import * as React from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import styled from "styled-components";

import BaseLayout from "@components/layout/BaseLayout";
import ScrollTop from "@components/shared/ScrollTop";

const IframeContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85rem;
`;

function RequestDemo() {
  const { i18n, t } = useTranslation("landing");

  return (
    <BaseLayout>
      <ScrollTop />
      <IframeContainer>
        <h1>Get a demo</h1>
        {i18n.language === "en" ? (
          <Iframe
            width="80%"
            height="100%"
            title="Embedded Wufoo Form"
            frameBorder={0}
            scrolling="yes"
            className="inbound-iframe"
            url="https://boletia.wufoo.com/embed/w1s7fhoz1hzi91k/"
            position="relative"
          />
        ) : (
          <Iframe
            width="80%"
            height="100%"
            title="Embedded Wufoo Form"
            frameBorder={0}
            scrolling="yes"
            className="inbound-iframe"
            url="https://boletia.wufoo.com/forms/s1ajnn511kug5ok/"
            position="relative"
          />
        )}
      </IframeContainer>
    </BaseLayout>
  );
}

export default RequestDemo;
