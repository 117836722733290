import * as React from "react";

import { CSSTransition } from "react-transition-group";
import { Route, useRouteMatch } from "react-router-dom";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import BaseLayout from "@components/layout/BaseLayout";

import Customization from "./Customization";
import Interaction from "./Interaction";
import Monetization from "./Monetization";
import Registration from "./Registration";
import Security from "./Security";

function RegistrationWithHeader() {
  const { t } = useTranslation(["features"]);

  return (
    <HeroStyled>
      {/* <HeroPresentation>
        <h1>{t("hero_title_1")}</h1>
        <h1>{t("hero_title_2")}</h1>
        <p>{t("hero_desc")}</p>
      </HeroPresentation> */}
      <Registration />
    </HeroStyled>
  );
}

const HeroStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroPresentation = styled.section`
  text-align: center;
  margin-bottom: 1.5em;
  h1 {
    margin: 0;
  }
  p {
    margin-bottom: 2em;
  }
`;

const UseCasesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
  .ucc-topic_navigator {
    text-align: center;
    position: sticky;
    top: 0;
    background: white;
    margin-bottom: 2em;
    .ucc-topic_navigator_menu {
      padding: 1em 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      .ucc-topic_navigator_menu_item {
        padding: 1.7em 3em;
        margin-left: 3em;
        border-radius: 13px;
        width: max-content;
        display: block;
        transition: all 0.2s linear;
      }
      .ucc-topic_navigator_menu_item.active {
        background-color: #502d53;
        font-weight: bold;
        color: white;
        font-size: 0.9em;
      }
    }
  }
  .ucc-topic_container {
    display: flex;
    justify-content: center;
  }

  .ucc-topic_container-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .ucc-topic_container-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 2s, transform 400ms;
  }

  .ucc-topic_container-exit {
    opacity: 0;
    height: 0;
  }

  .ucc-topic_container-exit-active {
    height: 0;
    opacity: 0;
  }

  .ucc-topic_picture {
    padding: 2em;
  }
  .ucc-topic_picture img {
    width: 375px;
  }
  .ucc-topic_content {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 3em;
  }
`;

interface RouteItem {
  path: string;
  name?: string;
  Component: React.FC<{ menuRef: React.RefObject<HTMLDivElement> }>;
}

function Features() {
  const { path } = useRouteMatch();

  const menuRef = React.useRef<HTMLDivElement>(null);

  const routes: Array<RouteItem> = [
    {
      path: `${path}/registration`,
      name: "Registration",
      Component: RegistrationWithHeader,
    },
    {
      path: `${path}/interaction`,
      name: "Interaction",
      Component: Interaction,
    },
    { path: `${path}/security`, name: "Security", Component: Security },
    {
      path: `${path}/customization`,
      name: "Customization",
      Component: Customization,
    },
    {
      path: `${path}/monetization`,
      name: "Monetization",
      Component: Monetization,
    },
  ];

  return (
    <>
      <div ref={menuRef}></div>
      <BaseLayout>
        <UseCasesContainer>
          {/* <p> {t("select_feature")}</p> */}
          {/* <div className="ucc-topic_navigator">
          <div className="ucc-topic_navigator_menu">
            <NavLink
              className="ucc-topic_navigator_menu_item"
              activeClassName="active"
              to={`${url}/registration`}
            >
              {t("topic_1")}
            </NavLink>
            <NavLink
              className="ucc-topic_navigator_menu_item"
              activeClassName="active"
              to={`${url}/interaction`}
            >
              {t("topic_2")}
            </NavLink>
            <NavLink
              className="ucc-topic_navigator_menu_item"
              activeClassName="active"
              to={`${url}/security`}
            >
              {t("topic_3")}
            </NavLink>
            <NavLink
              className="ucc-topic_navigator_menu_item"
              activeClassName="active"
              to={`${url}/customization`}
            >
              {t("topic_4")}
            </NavLink>
            <NavLink
              className="ucc-topic_navigator_menu_item"
              activeClassName="active"
              to={`${url}/monetization`}
            >
              {t("topic_5")}
            </NavLink>
          </div>
        </div> */}
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="ucc-topic_container"
                  unmountOnExit
                >
                  <div className="ucc-topic_container">
                    <Component menuRef={menuRef} />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
        </UseCasesContainer>
      </BaseLayout>
    </>
  );
}

export default Features;
